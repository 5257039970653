import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4128b25a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mt-4"
}
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "list-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.renderComponent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, key) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `${ key }-${ header }-${ Math.random().toString(36).slice(2, 7) }`
          }, [
            _createElementVNode("h5", _hoisted_2, _toDisplayString(header), 1),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objectTemplates, (_objectTemplate, key, index) => {
                return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.checkIfGetComponent(header===_objectTemplate.Stats[_ctx.statTypeEnum.Value].Data, _objectTemplate.Region, _objectTemplate.ObjectEnum)), {
                  key: `${ key }-${ index }`,
                  object: _objectTemplate,
                  index: key
                }, null, 8, ["object", "index"]))
              }), 128))
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}